import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import { jwtInterceptor } from './helpers/jwtInterceptor';
import { createAxiosResponseInterceptor } from './helpers/refreshTokenInterceptor';
import { setTheme } from './helpers/theme';
import { errorAxiosInterceptor } from './helpers/errorInterceptor';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en/common.json';
import de from './translations/de/common.json';
import LanguageProvider from './components/UI/language/LanguageProvider';

jwtInterceptor();
errorAxiosInterceptor();
createAxiosResponseInterceptor();
setTheme();

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['localStorage', 'navigator', 'sessionStorage'],
      caches: ['localStorage', 'sessionStorage'],
    },
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Error registering Service Worker:', error);
//     });
// }

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <BrowserRouter>
            <LanguageProvider i18n={i18n}>
              <App />
            </LanguageProvider>
          </BrowserRouter>
        </HelmetProvider>
      </I18nextProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
